import BaseTransport from './BaseTransport';

class SessionPackTransport extends BaseTransport {
    async getParticipation(uuid: string) {
        return await this.get(`/session-packs/${uuid}`);
    }

    async createPayment(uuid: string, amount: number, intent?: string) {
        return await this.post(`/session-packs/${uuid}/payments`, { amount, intent });
    }
}

export default new SessionPackTransport();
