import React, { FunctionComponent } from 'react';
import Message from '../Message';
import styles from './error-panel.module.scss';

interface Props {
    error?: string
}

const ErrorPanel: FunctionComponent<Props> = ({ error }) => {
    return (
        <div className={styles.container}>
            <Message type="negative" content={error!} />
        </div>
    );
};

export default ErrorPanel;
