import React, { FunctionComponent } from 'react';
import Button from '../Button';
import styles from './return-to-portal-button.module.scss';
import { usePro } from '../../Contexts/ProContext';
import classNames from 'classnames';

interface Props {
    className?: string,
    withMargin?: boolean,
    text?: string
}

const ReturnToPortalButton: FunctionComponent<Props> = ({ withMargin = true, text = 'Cancel', className }) => {
    const { pro } = usePro()!;

    if (!pro || !pro.portalUrl) {
        return null;
    }

    return (
        <Button href={pro.portalUrl} isFullWidth className={classNames({ [styles.container]: withMargin }, className)}>{text}</Button>
    );
};

export default ReturnToPortalButton;
