import React, { FormEvent, FunctionComponent, useState } from 'react';
import ContentSection from '../ContentSection';
import { PrimaryButton } from '../Button';
import styles from './payment-confirmation-panel.module.scss';
import ReturnToPortalButton from '../ReturnToPortalButton';
import { CustomerData } from '../../Screens/PaymentScreen';
import { ReactComponent as CheckIcon } from '../../Icons/check.svg';
import * as Sentry from "@sentry/react";

interface Props {
    title: string;
    customer?: CustomerData;
    onConfirmed(): void;
    isDisabled?: boolean;
    isSubmittable?: boolean;
}

const PaymentConfirmationPanel: FunctionComponent<Props> = ({
    title,
    customer,
    onConfirmed,
    isSubmittable = true,
    isDisabled,
    children,
}) => {
    const [isBusy, setIsBusy] = useState(false);

    async function handleConfirmed(event: FormEvent) {
        event.preventDefault();

        setIsBusy(true);

        try {
            await onConfirmed();
        } catch (e) {
            Sentry.captureException(e);

            console.log('Error confirming', e);
            setIsBusy(false);
        }
    }

    return (
        <ContentSection title={title}>
            <form onSubmit={handleConfirmed}>
                {children}

                <div className={styles.footer}>
                    {isSubmittable && (
                        <>
                            {customer && (
                                <div className={styles.customer}>
                                    <div>
                                        <CheckIcon />
                                    </div>
                                    <p className="font-bold">
                                        This purchase is for {customer.name}
                                    </p>
                                </div>
                            )}
                            <PrimaryButton
                                isFullWidth
                                isDisabled={isDisabled}
                                isLoading={isBusy}
                            >
                                Continue to payment
                            </PrimaryButton>
                        </>
                    )}
                    <ReturnToPortalButton withMargin={isSubmittable} />
                </div>
            </form>
        </ContentSection>
    );
};

export default PaymentConfirmationPanel;
