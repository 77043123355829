import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './portal-avatar.module.scss';
import { usePro } from '../../Contexts/ProContext';

interface Props {
    className?: string
}

const PortalAvatar: FunctionComponent<Props> = props => {
    const { pro } = usePro()!;

    if (!pro.brandLogo) {
        return null;
    }

    const classes = classNames(styles.container, {
        [styles.lightBorder]: pro.darkBrand
    });

    return (
        <img src={pro.brandLogo} className={classes} alt={pro.name} />
    );
};

export default PortalAvatar;
