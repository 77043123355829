import React, { FunctionComponent, useEffect, useState } from 'react';
import { CustomerData } from '../PaymentScreen';
import styles from '../../Components/MakePaymentForm/make-payment-form.module.scss';
import StripeWrapper from '../../Components/StripeWrapper';
import PayWithNewCardForm, { StripeResult } from '../../Components/PayWithNewCardForm';
import ReturnToPortalButton from '../../Components/ReturnToPortalButton';
import ContentSection from '../../Components/ContentSection';
import { usePro } from '../../Contexts/ProContext';
import AppHeader from '../../Components/AppHeader';
import AppContainer from '../../Components/AppContainer';
import { IntentData } from '../../Components/SpecifyAmountForm';
import AppLoader from '../../Components/AppLoader';
import ErrorPanel from '../../Components/ErrorPanel';
import CustomerTransport from '../../Transport/CustomerTransport';
import useQuery from '../../Hooks/useQuery';
import SuccessfulPayment from '../../Components/SuccessfulPayment';
import * as Sentry from "@sentry/react";

interface Props {

}

const errorString = 'Sorry, there was a problem. Please try again.';

const UpdateCardScreen: FunctionComponent<Props> = (props) => {
    const { pro, setPro } = usePro()!;
    const identifier = useQuery().get('identifier');
    const subscription = useQuery().get('subscription');
    const [ step, setStep ] = useState<'card' | 'success' | 'error'>('card');
    const [isLoading, setIsLoading] = useState(true);
    const [customer, setCustomer] = useState<CustomerData>();
    const [resource, setResource] = useState<any>();
    const [setupIntent, setSetupIntent] = useState<IntentData>();
    const [error, setError] = useState<string>();
    const [cardError, setCardError] = useState<string>();

    async function handleSuccess(result: StripeResult) {
        try {
            const intent = result.paymentIntent?.payment_method ?? result.setupIntent?.payment_method;
            await CustomerTransport.updateCard({
                identifier,
                subscription,
                intent,
            });
        }
        catch (e) {
            Sentry.captureException(e);

            console.log(e.response);
            setCardError(e.message ?? errorString);
            return;
        }

        setStep('success');
    }

    async function load() {
        try {
            const { data } = await CustomerTransport.getCardSetupIntent({ identifier, subscription });
            setPro(data.pro);
            setResource(data.resource);
            setCustomer(data.customer);
            setSetupIntent(data.intent);
        }
        catch (e) {
            Sentry.captureException(e);

            console.log(e.response);
            setError(errorString);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        if (identifier) {
            load();
        }
        else {
            setError(errorString);
        }
    }, [identifier]);

    if (isLoading) {
        return <AppLoader />;
    }

    if (error) {
        return <ErrorPanel error={error} />
    }

    return (
        <AppContainer>
            <AppHeader />

            {step === 'card' && (
                <ContentSection title={`Update card for '${resource.name}'`} error={cardError}>
                    <div className={styles.section}>
                        <StripeWrapper accountId={pro.accountId!}>
                            <PayWithNewCardForm
                                buttonText='Update card'
                                customer={customer!}
                                paymentIntent={setupIntent!}
                                onSuccess={handleSuccess}
                                onError={setCardError}
                                onBeforeConfirm={() => {}}
                                resource={resource}
                            />
                        </StripeWrapper>

                        <ReturnToPortalButton />
                    </div>
                </ContentSection>
            )}
            {step === 'success' && <SuccessfulPayment content="Your card has been updated" />}
        </AppContainer>
    );
};

export default UpdateCardScreen;
