import { DateTime } from 'luxon';

export const formatDate = (date: string | DateTime, includeDay = true) : string => {
    const converted = typeof date === 'string' ? DateTime.fromISO(date, {setZone: true}) : date;

    let formatted = converted.day + ' ' + converted.toFormat('MMM');

    if (includeDay) {
        formatted = `${converted.toFormat('ccc')} ${formatted}`;
    }

    return formatted;
}

export const formatTime = (date: string | DateTime) : string => {
    const converted = typeof date === 'string' ? DateTime.fromISO(date, {setZone: true}) : date;

    return converted.toFormat('HH:mm');
}