import React, { FunctionComponent } from 'react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import styles from './specify-amount-form.module.scss';
import SessionPackTransport from '../../Transport/SessionPackTransport';
import FormTextField from '../FormTextField';
import SessionPackParticipation from '../../Types/SessionPackParticipation';
import CurrencyField from '../CurrencyField';
import PaymentConfirmationPanel from '../PaymentConfirmationPanel';
import { CustomerData } from '../../Screens/PaymentScreen';
import * as Sentry from "@sentry/react";

const validationSchema = Yup.object().shape({
    amount: Yup.number()
        .typeError('Please specify a valid amount')
        .required('Please specify an amount')
        .min(1, 'Please specify a valid amount'),
});

interface Props {
    amount?: number;
    customer?: CustomerData;
    participation: SessionPackParticipation;
    paymentIntent?: IntentData;
    onSuccess(paymentIntent: IntentData): void;
    title?: string;
}

interface FormValues {
    amount: number;
}

export interface IntentData {
    type: 'payment' | 'setup';
    intentId: string;
    clientSecret: string;
    amount?: number;
}

const SpecifyAmountForm: FunctionComponent<Props & FormikProps<FormValues>> = ({
    children,
    customer,
    title = 'Confirm amount',
    ...props
}) => {
    const { handleSubmit, participation } = props;

    return (
        <PaymentConfirmationPanel
            customer={customer}
            title={title}
            onConfirmed={handleSubmit}
            isSubmittable={participation.outstanding > 0}
        >
            <div className={styles.section}>{children}</div>

            {participation.outstanding > 0 && (
                <div className={styles.fieldsSection}>
                    <FormTextField
                        name="amount"
                        label="Amount to pay now"
                        input={(fieldProps) => (
                            <CurrencyField
                                currency={props.participation.currency}
                                {...fieldProps}
                            />
                        )}
                    />
                </div>
            )}
        </PaymentConfirmationPanel>
    );
};

export default withFormik<Props, FormValues>({
    validationSchema,
    validateOnMount: true,
    mapPropsToValues: (props) => ({
        amount: props.amount ?? props.participation.outstanding,
    }),
    handleSubmit: async (values, { props, setErrors }) => {
        try {
            const { data } = await SessionPackTransport.createPayment(
                props.participation.uuid,
                values.amount,
                props.paymentIntent?.intentId
            );
            props.onSuccess(data);
        } catch (e) {
            Sentry.captureException(e);

            console.log('error', e);

            if (e.response.status === 422) {
                setErrors(e.response.data.errors);
                return;
            }
        }
    },
})(SpecifyAmountForm);
