import React, { forwardRef, InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './text-input-field.module.scss';

interface Props {
    placeholder?: string,
    readOnly?: boolean,
    value?: string,
    className?: string,
}

const TextInputField = forwardRef<HTMLInputElement, Props & InputHTMLAttributes<HTMLInputElement>>(
    ({ className, ...props }, ref) => (
        <input className={classNames(styles.input, className)} {...props} autoCorrect="off" ref={ref} />
    )
);

export default TextInputField;
