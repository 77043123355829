import React, { FunctionComponent } from 'react';
import styles from './content-section.module.scss';
import classNames from 'classnames';
import Message from '../Message';

interface Props {
    title: string,
    error?: string,
    className?: string
}

const ContentSection: FunctionComponent<Props> = ({ title, error, className, children }) => {
    return (
        <div className={classNames(styles.container, className)}>
            <header className={styles.header}>
                <h3>{title}</h3>
            </header>

            {error && (
                <div className={styles.section}>
                    <Message type="negative" content={error} />
                </div>
            )}

            <section className={styles.content}>
                {children}
            </section>
        </div>
    );
};

export default ContentSection;
