import React, { FormEvent, FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './class-date-payment-screen.module.scss';
import PaymentScreen, {
    PaymentAmountConfirmation,
    RenderConfirmationProps,
} from '../PaymentScreen';
import { useTransactable } from '../../Contexts/TransactableResourceContext';
import ClassDate from '../../Types/ClassDate';
import { currencyDisplay } from '../../Services/Currency';
import ClassDateTransport from '../../Transport/ClassDateTransport';
import useQuery from '../../Hooks/useQuery';
import PaymentConfirmationPanel from '../../Components/PaymentConfirmationPanel';
import ResourceData from '../../Components/ResourceData';
import { formatDate, formatTime } from '../../Services/Date';
import Message from '../../Components/Message';

interface RouteParams {
    uuid: string;
}

const ClassDatePaymentScreen: FunctionComponent = () => {
    const { setAmount } = useTransactable()!;
    const identifier = useQuery().get('identifier');
    let { uuid: classDateUuid } = useParams<RouteParams>();

    if (!identifier) {
        return <p>Error. No identifier</p>;
    }

    async function handleLoadPaymentData() {
        const data = await ClassDateTransport.getClassDate(classDateUuid, {
            identifier,
        });
        setAmount(data.data.resource.outstanding);
        return data;
    }

    async function handlePurchaseConfirmed(
        onConfirmed: PaymentAmountConfirmation
    ) {
        const { data } = await ClassDateTransport.createPurchase(
            classDateUuid,
            {
                identifier,
            }
        );
        onConfirmed(data);
    }

    function handleRenderConfirmation({
        resource,
        customer,
        handleConfirmed,
    }: RenderConfirmationProps<ClassDate>) {
        return (
            <PaymentConfirmationPanel
                customer={customer}
                title={resource.name}
                isSubmittable={resource.hasSpace && !resource.isEnrolled}
                onConfirmed={async () =>
                    await handlePurchaseConfirmed(handleConfirmed)
                }
            >
                <div className={styles.section}>
                    {resource.isEnrolled && (
                        <Message
                            type="negative"
                            content="You have already enrolled on this class"
                        />
                    )}
                    {!resource.isEnrolled && resource.hasSpace && (
                        <Message
                            type="positive"
                            content="Your space is available!"
                        />
                    )}
                    {!resource.isEnrolled && !resource.hasSpace && (
                        <Message
                            type="negative"
                            content="Sorry, this class is now full!"
                        />
                    )}

                    <div style={{ marginTop: 16 }}>
                        <ResourceData
                            label="When"
                            content={`${formatDate(
                                resource.start
                            )} - ${formatTime(resource.start)} (${
                                resource.duration
                            })`}
                        />
                        <ResourceData
                            label="Where"
                            content={resource.location ?? 'No location'}
                        />

                        <p className={styles.cost}>
                            {currencyDisplay(resource.cost, resource.currency)}
                        </p>
                    </div>
                </div>
            </PaymentConfirmationPanel>
        );
    }

    async function handleConfirmationAttempted() {
        return await ClassDateTransport.confirmSpaces(classDateUuid, {
            identifier,
        });
    }

    return (
        <PaymentScreen
            errorString="This class is either full, or has been cancelled."
            amountEditable={false}
            onBeforeConfirm={handleConfirmationAttempted}
            loadPaymentData={handleLoadPaymentData}
            renderConfirmation={handleRenderConfirmation}
        />
    );
};

export default ClassDatePaymentScreen;
