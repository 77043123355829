import { createContext, useContext } from 'react';

export interface Pro {
    accountId?: string,
    brandLogo?: string,
    brandColor?: string,
    portalUrl?: string,
    name?: string,
    businessName?: string
}

export interface ProTheme extends Pro {
    darkBrand: boolean
}

export interface ProContextInterface {
    pro: ProTheme;
    setPro(Pro: Pro): void;
}

export const ProContext = createContext<ProContextInterface | undefined>(undefined);
export const usePro = () => useContext(ProContext);
