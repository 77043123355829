import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './loader.module.scss';
import { capitalize } from '../../Services/String';

interface Props {
    variant?: 'light' | 'dark',
    size?: 'small' | 'large',
    className?: string
}

const Loader: FunctionComponent<Props> = ({ variant = 'dark', size = 'small', className }) => {
    const variantStyle = styles[`variant${capitalize(variant)}`];
    const sizeStyle = styles[`size${capitalize(size)}`];

    return (
        <div className={classNames(styles.container, sizeStyle, className)}>
            <div className={classNames(styles.spinner, variantStyle, sizeStyle)}></div>
        </div>
    );
};

export default Loader;