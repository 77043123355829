import React, { FunctionComponent } from 'react';
import styles from './resource-data.module.scss';

interface Props {
    label: string,
    content: string,
}

const ResourceData: FunctionComponent<Props> = ({ label, content }) => {
    return (
        <div className={styles.container}>
            <p className={styles.label}>{label}</p>
            {content && content.split('\n').map((para: string, index: number) => (
                <p key={index} className={styles.content}>{para}</p>
            ))}
        </div>
    );
};

export default ResourceData;
