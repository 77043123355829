import React, { FormEvent, Fragment, FunctionComponent, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import * as Sentry from '@sentry/react';
import TextInputField from '../TextInputField';
import styles from '../PaymentMethodList/payment-method-list.module.scss';
import CheckboxField from '../CheckboxField';
import FormField from '../FormField';
import { PrimaryButton } from '../Button';
import { PaymentFormProps } from '../MakePaymentForm';
import { PaymentIntent, SetupIntent, StripeError } from '@stripe/stripe-js';

interface Props extends PaymentFormProps {
    buttonText: string,
    onError(error: string): void
}

export interface StripeResult {
    paymentIntent?: PaymentIntent,
    setupIntent?: SetupIntent,
    error?: StripeError
}

const PayWithNewCardForm: FunctionComponent<Props> = (props) => {
    const { buttonText, paymentIntent, onError, onBeforeConfirm, onSuccess, resource } = props;
    const stripe = useStripe();
    const elements = useElements();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [storeCard, setStoreCard] = useState(false);
    const [cardholderName, setCardholderName] = useState<string>('');

    const isValid = stripe && cardholderName && !isSubmitting;

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        setIsSubmitting(true);

        try {
            await onBeforeConfirm();
        } catch (e) {
            Sentry.captureException(e);

            onError(e.response?.data ?? 'Error');
            setIsSubmitting(false);
            return;
        }

        let result: StripeResult = {};

        const paymentMethodData = {
            card: elements.getElement(CardElement)!,
            billing_details: {
                name: cardholderName,
            },
        };

        try {
            if (paymentIntent.type === 'payment') {
                result = await stripe!.confirmCardPayment(paymentIntent.clientSecret, {
                    payment_method: paymentMethodData,
                    setup_future_usage: storeCard ? 'on_session' : null,
                });
            } else {
                result = await stripe!.confirmCardSetup(paymentIntent.clientSecret, {
                    payment_method: paymentMethodData
                })
            }
        }
        catch (e) {
            Sentry.captureException(e);
            onError('An unexpected error occurred. Please try again');
        }

        if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
            onError(result.error.message ?? 'There was a problem. Please try again');
        } else {
            // The payment has been processed!
            if (result.paymentIntent?.status === 'succeeded' || result.setupIntent?.status === 'succeeded') {
                await onSuccess(result);
            }
        }

        setIsSubmitting(false);
    }

    return (
        <form onSubmit={handleSubmit}>
            <FormField>
                <FormField.Label for='cardholderName'>Cardholder's name</FormField.Label>
                <TextInputField
                    id='cardholderName'
                    value={cardholderName}
                    onChange={event => setCardholderName(event.target.value)}
                    placeholder='e.g. John Smith'
                />
            </FormField>

            <FormField>
                <FormField.Label>Card details</FormField.Label>
                <CardElement className={styles.element} options={{ style: { base: { fontSize: '16px' } } }} />
            </FormField>

            {resource.type !== 'membership' && (
                <FormField>
                    <CheckboxField
                        id='storeCard'
                        label='Store card details for future use?'
                        onChange={event => setStoreCard(event.target.checked)}
                        checked={storeCard}
                    />
                </FormField>
            )}

            <PrimaryButton
                isFullWidth
                isDisabled={!isValid}
                isLoading={isSubmitting}
            >
                {buttonText}
            </PrimaryButton>
        </form>
    );
};

export default PayWithNewCardForm;
