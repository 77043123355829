import React, { FunctionComponent } from 'react';
import styles from './thank-you-screen.module.scss';
import AppContainer from '../../Components/AppContainer';
import AppHeader from '../../Components/AppHeader';

interface Props {

}

const NoMatchScreen: FunctionComponent<Props> = (props) => {
    return (
        <AppContainer className={styles.container}>
            <AppHeader>
                <h1>404 - Not found</h1>
                <p>There's nothing here.</p>
            </AppHeader>
        </AppContainer>
    );
};

export default NoMatchScreen;
