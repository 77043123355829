import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './input-group.module.scss';

interface Props {
    prepend?: string | JSX.Element,
    append?: string | JSX.Element,
    onClick?(): void
}

const InputGroup: FunctionComponent<Props> = ({ prepend, append, onClick, children }) => {
    const renderPrepend = () => {
        if (!prepend) {
            return;
        }

        return (
            <div className={styles.prepend} onClick={onClick}>
                {prepend}
            </div>
        )
    };

    const renderAppend = () => {

    };

    const classes = classNames(styles.container, {
        // [styles.prepend]: !!prepend,
        // [styles.append]: !!append,
    });

    return (
        <div className={classes}>
            {renderPrepend()}
            {children}
            {renderAppend()}
        </div>
    );
};

export default InputGroup;
