import React from 'react';
import classNames from 'classnames';
import styles from './form-field.module.scss';
import Label from './Label';

const FormField = ({ className = null, ...props }) => <div className={classNames(styles.container, className)} {...props}/>;

FormField.Label = Label;
FormField.Row = ({ className = null, ...props }) => <div className={classNames('form-field-group', className)} {...props}/>;
FormField.Info = ({ className = null, ...props }) => <div className={classNames('form-field__info', className)} {...props}/>;
FormField.Error = ({ className = null, ...props }) => <div className={classNames(styles.error, className)} {...props}/>;
FormField.Input = ({ className = null, ...props }) => <div className={classNames('form-field__field', className)} {...props}/>;

export default FormField;
