import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { usePro } from '../../Contexts/ProContext';
import styles from './app-header.module.scss';
import PortalAvatar from '../PortalAvatar';

interface Props {
    className?: string
}

const AppHeader: FunctionComponent<Props> = ({ className, children }) => {
    const { pro } = usePro()!;

    return (
        <header className={classNames(styles.container, {[styles.lightStyles]: pro.darkBrand}, className)}>
            <PortalAvatar />
            <h1>{pro!.name}</h1>
            {pro!.businessName && <p>{pro!.businessName}</p>}

            {children}
        </header>
    );
};

export default AppHeader;
