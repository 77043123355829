import React, { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import useQuery from "../../Hooks/useQuery";
import styles from "./package-payment-screen.module.scss";
import PaymentScreen, {
  PaymentAmountConfirmation,
  RenderConfirmationProps,
} from "../PaymentScreen";
import PackageTransport from "../../Transport/PackageTransport";
import CurrencyData from "../../Types/CurrencyData";
import ResourceData from "../../Components/ResourceData";
import PaymentConfirmationPanel from "../../Components/PaymentConfirmationPanel";
import { currencyDisplay } from "../../Services/Currency";

interface PackageData {
  name: string;
  description: string;
  terms: string;
  cost: number;
  currency: CurrencyData;
}

interface RouteParams {
  uuid: string;
}

const PackagePaymentScreen: FunctionComponent = (props) => {
  const { uuid: packageUuid } = useParams<RouteParams>();
  const identifier = useQuery().get("identifier");

  if (!identifier) {
    return <p>Error. No identifier</p>;
  }

  async function handleLoadPaymentData() {
    return await PackageTransport.getPackage(packageUuid, { identifier });
  }

  async function handlePurchaseConfirmed(
    onConfirmed: PaymentAmountConfirmation
  ) {
    const { data } = await PackageTransport.createPurchase(packageUuid, {
      identifier,
    });
    onConfirmed(data);
  }

  function handleRenderConfirmation({
    resource,
    customer,
    handleConfirmed,
  }: RenderConfirmationProps<PackageData>) {
    return (
      <PaymentConfirmationPanel
        customer={customer}
        title={resource.name}
        onConfirmed={async () => await handlePurchaseConfirmed(handleConfirmed)}
      >
        <div className={styles.description}>
          {resource.description}

          <p className={styles.cost}>
            {currencyDisplay(resource.cost, resource.currency)}
          </p>
        </div>
        <div className={styles.terms}>
          <ResourceData label="Terms" content={resource.terms ?? "No terms"} />
        </div>
      </PaymentConfirmationPanel>
    );
  }

  return (
    <PaymentScreen
      errorString="It appears as though this package no longer exists."
      amountEditable={false}
      loadPaymentData={handleLoadPaymentData}
      renderConfirmation={handleRenderConfirmation}
    />
  );
};

export default PackagePaymentScreen;
