import React, { FunctionComponent, SyntheticEvent } from 'react';
import classNames from 'classnames';
import styles from './button.module.scss';
import { capitalize } from '../../Services/String';
import { usePro } from '../../Contexts/ProContext';

export interface Props {
    className?: string
    onClick?(ev: SyntheticEvent): void,
    isDisabled?: boolean,
    isLoading?: boolean,
    isFullWidth?: boolean,
    isResponsive?: boolean,
    size?: 'large',
    type?: 'submit' | 'reset' | 'button',
    href?: string,
    target?: string,
    rel?: string
}

/**
 * Default button
 * @param className
 * @param isLoading
 * @param isDisabled
 * @param isResponsive
 * @param isFullWidth
 * @param size
 * @param href
 * @param target
 * @param rel
 * @param props
 * @constructor
 */
export const Button: FunctionComponent<Props> = ({ className, isLoading, isDisabled, isResponsive, isFullWidth, size, href, target, rel, ...props }) => {
    const getClassNames = (): string => {
        const optionals = {
            [styles.isLoading]: isLoading,
            [styles.isDisabled]: isDisabled || isLoading,
            [styles.isFullWidth]: isFullWidth,
            [styles.isResponsive]: isResponsive,
        };

        if (typeof size !== 'undefined') {
            const styleName = `size${capitalize(size)}`;
            optionals[styles[styleName]] = true;
        }

        return classNames(styles.default, className, optionals);
    };

    if (typeof href !== 'undefined') {
        return (
            <a className={getClassNames()} href={href} target={target} rel={rel} {...props}>
                <span>{props.children}</span>
            </a>
        );
    }

    return (
        <button className={getClassNames()} disabled={isDisabled || isLoading} {...props}>
            <span>{props.children}</span>
        </button>
    );
}

/**
 * Default button
 * @param props
 * @constructor
 */

export const PrimaryButton: FunctionComponent<Props> = props => {
    return <Button className={styles.variantPrimary} {...props} />
}

export const SemiTransparentButton: FunctionComponent<Props> = ({ className, ...props }) => {
    const { pro } = usePro()!;
    return <Button {...props} className={classNames(styles.variantSemiTransparent, {[styles.lightBackground]: pro.darkBrand }, className)} />
}

export const TextButton: FunctionComponent<Props> = props => {
    return <Button {...props} className={classNames(styles.variantText, props.className)} />
}

export default Button;
