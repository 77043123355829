import React, { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import useQuery from "../../Hooks/useQuery";
import styles from "./membership-payment-screen.module.scss";
import PaymentScreen, {
  PaymentAmountConfirmation,
  RenderConfirmationProps,
} from "../PaymentScreen";
import MembershipTransport from "../../Transport/MembershipTransport";
import CurrencyData from "../../Types/CurrencyData";
import ResourceData from "../../Components/ResourceData";
import PaymentConfirmationPanel from "../../Components/PaymentConfirmationPanel";
import { currencyDisplay } from "../../Services/Currency";
import Message from "../../Components/Message";

interface MembershipData {
  name: string;
  description: string;
  terms: string;
  cost: number;
  currency: CurrencyData;
  interval: string;
  hasRenewalAnchor: boolean;
}

interface RouteParams {
  uuid: string;
}

const MembershipPaymentScreen: FunctionComponent = (props) => {
  const { uuid: membershipUuid } = useParams<RouteParams>();
  const identifier = useQuery().get("identifier");

  if (!identifier) {
    return <p>Error. No identifier</p>;
  }

  async function handleLoadPaymentData() {
    return await MembershipTransport.getMembership(membershipUuid, {
      identifier,
    });
  }

  async function handlePurchaseConfirmed(
    onConfirmed: PaymentAmountConfirmation
  ) {
    const { data } = await MembershipTransport.createSubscription(
      membershipUuid,
      { identifier }
    );
    onConfirmed(data.paymentIntent);
  }

  function handleRenderConfirmation({
    resource,
    customer,
    handleConfirmed,
  }: RenderConfirmationProps<MembershipData>) {
    return (
      <PaymentConfirmationPanel
        customer={customer}
        title={resource.name}
        onConfirmed={async () => await handlePurchaseConfirmed(handleConfirmed)}
      >
        <div className={styles.description}>
          {resource.description}

          <p className={styles.cost}>
            {currencyDisplay(resource.cost, resource.currency)} per{" "}
            {resource.interval}
          </p>

          {resource.hasRenewalAnchor && (
            <Message
              type="warning"
              content={`Your first payment will be less than the regular ${
                resource.interval
              }ly payment of ${currencyDisplay(
                resource.cost,
                resource.currency
              )}`}
            />
          )}
        </div>

        <div className={styles.terms}>
          <ResourceData label="Terms" content={resource.terms ?? "No terms"} />
        </div>
      </PaymentConfirmationPanel>
    );
  }

  return (
    <PaymentScreen
      errorString="It appears as though this membership no longer exists."
      amountEditable={false}
      loadPaymentData={handleLoadPaymentData}
      renderConfirmation={handleRenderConfirmation}
    />
  );
};

export default MembershipPaymentScreen;
