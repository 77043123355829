import BaseTransport from './BaseTransport';

class CustomerTransport extends BaseTransport {
    async getPaymentMethods(params = {}) {
        return await this.get(`/payment-methods`, params);
    }

    async getCardSetupIntent(params = {}) {
        return await this.get(`/card/intent`, params);
    }

    async updateCard(params = {}) {
        return await this.post(`/card/update`, params);
    }
}

export default new CustomerTransport();
