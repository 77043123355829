import React, { FormEvent, FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";
import SessionPackTransport from "../../Transport/SessionPackTransport";
import SpecifyAmountForm from "../../Components/SpecifyAmountForm";
import PaymentScreen, { RenderConfirmationProps } from "../PaymentScreen";
import { useTransactable } from "../../Contexts/TransactableResourceContext";
import SessionPackParticipation from "../../Types/SessionPackParticipation";
import { currencyDisplay } from "../../Services/Currency";
import ResourceData from "../../Components/ResourceData";
import Message from "../../Components/Message";

interface RouteParams {
  uuid: string;
}

const SessionPackPaymentScreen: FunctionComponent = () => {
  const { amount, setAmount, paymentIntent } = useTransactable()!;
  let { uuid: participationUuid } = useParams<RouteParams>();

  async function handleLoadPaymentData() {
    const data = await SessionPackTransport.getParticipation(participationUuid);
    setAmount(data.data.resource.outstanding);
    return data;
  }

  function handleRenderConfirmation({
    resource,
    customer,
    handleConfirmed,
  }: RenderConfirmationProps<SessionPackParticipation>) {
    return (
      <SpecifyAmountForm
        customer={customer}
        participation={resource}
        amount={amount!}
        paymentIntent={paymentIntent}
        onSuccess={handleConfirmed}
        title={resource.name}
      >
        {resource.outstanding <= 0 && (
          <Message type="positive" content="Session pack is fully paid" />
        )}

        <ResourceData label="Session pack" content={resource.reference} />
        <ResourceData
          label="Type"
          content={resource.isPrivate ? "Private" : "Group"}
        />
        <ResourceData
          label="Current balance"
          content={currencyDisplay(
            resource.outstanding,
            resource.currency,
            false
          )}
        />
      </SpecifyAmountForm>
    );
  }

  return (
    <PaymentScreen
      errorString="It appears as though this session pack no longer exists, or has been paid in full."
      amountEditable={true}
      loadPaymentData={handleLoadPaymentData}
      renderConfirmation={handleRenderConfirmation}
    />
  );
};

export default SessionPackPaymentScreen;
