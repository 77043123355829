/**
 * Capitalise the first char
 * @param s
 */
export const capitalize = (s: string): string => {
    return s.charAt(0).toUpperCase() + s.slice(1)
};

/**
 * Return the possessive of the given input
 * @param input
 */
export const possessive = (input: string): string => {
    const suffix = input.substring(-1) === 's' ? '' : 's';
    return `${input}'${suffix}`;
};