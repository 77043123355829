import React, { FunctionComponent, useState } from 'react';
import Color from 'color';
import { ProTheme, ProContext } from '../../Contexts/ProContext';

const initialTheme = {
    brandColor: '#016bff',
    darkBrand: true,
};

export const ProProvider: FunctionComponent = ({ children }) => {
    const [ pro, setPro ] = useState<ProTheme>(initialTheme);

    function handleSetPro(theme: ProTheme) {
        const luminosity = Color(pro.brandColor ?? initialTheme.brandColor).luminosity();

        setPro({
            ...initialTheme,
            ...theme,
            darkBrand: luminosity < 0.5
        });
    }

    return (
        <ProContext.Provider value={{ pro, setPro: handleSetPro }}>
            {children}
        </ProContext.Provider>
    );
};