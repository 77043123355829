import React, { FormEvent, FunctionComponent, useState } from 'react';
import styles from '../PaymentMethodList/payment-method-list.module.scss';
import { CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import FormField from '../FormField';
import { PaymentFormProps } from '../MakePaymentForm';
import { currencyDisplay } from '../../Services/Currency';
import { PrimaryButton } from '../Button';
import * as Sentry from "@sentry/react";

interface Props extends PaymentFormProps {
    buttonText: string,
    paymentMethodId: string,
    onError(error: string): void
}

const PayWithExistingCardForm: FunctionComponent<Props> = ({ buttonText, paymentIntent, paymentMethodId, onBeforeConfirm, onSuccess, onError }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isSubmitting, setIsSubmitting] = useState(false);

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        setIsSubmitting(true);

        try {
            await onBeforeConfirm();
        }
        catch (e) {
            Sentry.captureException(e);

            onError(e.response?.data ?? "Error");
            setIsSubmitting(false);
            return;
        }

        const result = await stripe.confirmCardPayment(paymentIntent!.clientSecret, {
            payment_method: paymentMethodId,
            payment_method_options: {
                card: {
                    cvc: elements.getElement(CardCvcElement)!
                }
            }
        });

        if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            onError(result.error.message ?? 'There was a problem. Please try again');
            setIsSubmitting(false);
        } else {
            setIsSubmitting(false);

            // The payment has been processed!
            if (result.paymentIntent?.status === 'succeeded') {
                try {
                    onSuccess(result);
                }
                catch (e) {
                    Sentry.captureException(e);

                    console.log('onsuccess error', e);
                }
            }
        }
    }

    const isValid = stripe && !isSubmitting;

    return (
        <form onSubmit={handleSubmit}>
            <FormField className={styles.method}>
                <FormField.Label>Confirm CVC</FormField.Label>
                <CardCvcElement className={styles.element} options={{ style: { base: { fontSize: '16px' }}}} />
            </FormField>

            <PrimaryButton
                isFullWidth
                isDisabled={!isValid}
                isLoading={isSubmitting}
            >
                {buttonText}
            </PrimaryButton>
        </form>
    );
};

export default PayWithExistingCardForm;
