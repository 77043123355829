import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProProvider } from './Components/ProProvider';
import { TransactableResourceProvider } from './Components/TransactableResourceProvider';
import { createBrowserHistory } from 'history';
import * as Sentry from "@sentry/react";

export const history = createBrowserHistory();

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
        new Sentry.Replay()
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV
});

ReactDOM.render(
    <React.StrictMode>
        <ProProvider>
            <TransactableResourceProvider>
                <App/>
            </TransactableResourceProvider>
        </ProProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
