import BaseTransport from './BaseTransport';

class ClassDateTransport extends BaseTransport {
    async getClassDate(uuid: string, params = {}) {
        return await this.get(`/class-dates/${uuid}`, params);
    }

    async createPurchase(uuid: string, params = {}) {
        return await this.post(`/class-dates/${uuid}/payments`, params);
    }

    async confirmSpaces(uuid: string, params = {}) {
        return await this.post(`/class-dates/${uuid}/available`, params);
    }
}

export default new ClassDateTransport();
