import React, {
    KeyboardEvent,
    FunctionComponent,
    SyntheticEvent,
    useEffect,
    useRef,
    useState,
} from 'react';
import { getCurrencyDivisor } from '../../Services/Currency';
import TextInputField from '../TextInputField';
import InputGroup from '../InputGroup';
import CurrencyData from '../../Types/CurrencyData';

const charRegex = new RegExp("^[0-9-.]$");

interface Props {
    value: number,
    currency: CurrencyData,
    onChange(ev: any): void,
    onBlur?(ev: any): void,
}

const CurrencyField: FunctionComponent<Props> = ({ value, currency, onChange, ...props }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const currencyDivisor = getCurrencyDivisor(currency);

    const [ viewValue, setViewValue ] = useState(getViewValue(value));
    const [ cursor, setCursor ] = useState<number | null>(1);

    function getModelValue(value: string) {
        return '' + Math.round(Number(value) * currencyDivisor)
    }

    function getViewValue(value: number) {
        return (value / currencyDivisor).toFixed(currency.decimals);
    }

    const handleKeyPress = (ev: KeyboardEvent<HTMLInputElement>) => {
        if (!charRegex.test(ev.key)) {
            ev.preventDefault();
            return false;
        }
    }

    const handleChange = (ev: SyntheticEvent<HTMLInputElement>) => {
        const target = ev.target as HTMLInputElement;

        setCursor(target.selectionStart);

        if (target.value !== '') {
            setViewValue(target.value);

            if (!isNaN(Number(target.value))) {
                target.value = getModelValue(target.value);
            }
        }

        if (typeof onChange === 'function') {
            ev.target = target;
            onChange(ev);
        }
    }

    const handleBlur = (ev: SyntheticEvent<HTMLInputElement>) => {
        const target = ev.target as HTMLInputElement;

        if (!isNaN(Number(target.value))) {
            setViewValue(Number(target.value).toFixed(currency.decimals));
        }

        if (typeof props.onBlur === 'function') {
            props.onBlur(ev);
        }
    }

    useEffect(() => {
        if (isNaN(Number(value))) {
            return;
        }

        const stringValue = ''+value;

        /**
         * If the field is blank, display it as so. This is instead of defaulting to '0' when the field is empty
         */
        if (stringValue === '') {
            setViewValue('');
        }
        /**
         * Only if the parsed values differ, do we need to update the view. Otherwise we will prematurely change the
         * input value, annoying the user
         */
        else if (getModelValue(getViewValue(value)) !== getModelValue(viewValue)) {
            setViewValue(getViewValue(value));
        }
    }, [ value ]);

    useEffect(() => {
        if (inputRef.current !== null) {
            inputRef.current!.selectionEnd = cursor;
        }
    }, [ viewValue ]);

    return (
        <InputGroup prepend={currency.symbol}>
            <TextInputField
                {...props}
                value={viewValue}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
                ref={inputRef}
            />
        </InputGroup>
    );
};

export default CurrencyField;
