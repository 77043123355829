import React, { FunctionComponent, useState, Fragment } from 'react';
import styles from './make-payment-form.module.scss';
import { IntentData } from '../SpecifyAmountForm';
import ContentSection from '../ContentSection';
import Button from '../Button';
import PaymentMethodList from '../PaymentMethodList';
import PaymentMethod from '../../Types/PaymentMethod';
import PayWithNewCardForm, { StripeResult } from '../PayWithNewCardForm';
import PayWithExistingCardForm from '../PayWithExistingCardForm';
import StripeWrapper from '../StripeWrapper';
import { usePro } from '../../Contexts/ProContext';
import { CustomerData } from '../../Screens/PaymentScreen';
import ReturnToPortalButton from '../ReturnToPortalButton';
import { currencyDisplay } from '../../Services/Currency';

export interface PaymentFormProps {
    customer: CustomerData,
    paymentIntent: IntentData,
    onSuccess(result: StripeResult): void,
    onBeforeConfirm(): void,
    resource: any,
}

interface Props extends PaymentFormProps {
    amountEditable: boolean,
    onRequestChangeAmount?(): void
}

const MakePaymentForm: FunctionComponent<Props> = (props) => {
    const { resource, paymentIntent, customer, onBeforeConfirm, onRequestChangeAmount, amountEditable, onSuccess } = props;
    const { pro } = usePro()!;

    const [hasLoaded, setHasLoaded] = useState(resource.type === 'membership');
    const [cardError, setCardError] = useState<string>();
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>({ type: 'new' });
    const buttonText = resource.type === 'membership'
        ? 'Subscribe'
        : `Pay ${currencyDisplay(paymentIntent.amount!, resource!.currency)}`

    const formProps = {
        buttonText,
        customer,
        paymentIntent,
        onBeforeConfirm,
        resource,
        onSuccess,
        onError: (error: string) => setCardError(error)
    };

    return (
        <ContentSection title="Make payment" error={cardError}>
            <div className={styles.section}>
                {resource.type !== 'membership' && (
                    <PaymentMethodList
                        customer={customer}
                        paymentMethod={paymentMethod}
                        onMethodsLoaded={() => setHasLoaded(true)}
                        onPaymentMethodChange={setPaymentMethod}
                    />
                )}

                {hasLoaded && (
                    <Fragment>
                        {paymentMethod.type === 'new' && (
                            <StripeWrapper accountId={pro.accountId!}>
                                <PayWithNewCardForm {...formProps} />
                            </StripeWrapper>
                        )}
                        {paymentMethod.type === 'existing' && (
                            <StripeWrapper accountId={pro.accountId!}>
                                <PayWithExistingCardForm {...formProps} paymentMethodId={paymentMethod.paymentMethodId!} />
                            </StripeWrapper>
                        )}
                    </Fragment>
                )}

                {hasLoaded && amountEditable && onRequestChangeAmount && (
                    <Button isFullWidth onClick={onRequestChangeAmount} className={styles.button}>Change amount</Button>
                )}

                <ReturnToPortalButton />
            </div>
        </ContentSection>
    );
};

export default MakePaymentForm;
