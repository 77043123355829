import React from 'react';
import styles from './app-loader.module.scss';
import Loader from '../Loader';

const AppLoader = () => {
    return (
        <div className={styles.container}>
            <Loader />
        </div>
    );
};

export default AppLoader;