import React, { FunctionComponent, InputHTMLAttributes } from 'react';
import styles from './checkbox-field.module.scss';
import classNames from 'classnames';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string,
    className?: string,
    isDisabled?: boolean
}

const CheckboxField: FunctionComponent<Props> = ({ label, isDisabled, className, ...props }) => {
    return (
        <label className={classNames(styles.container, className)}>
            <input type="checkbox" disabled={isDisabled} {...props} />
            <span>{label}</span>
        </label>
    );
};

export default CheckboxField;
