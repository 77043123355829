import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import FormField from '../FormField';
import TextInputField from '../TextInputField';

const FormTextField = ({ name, label, input, placeholder, inputType = 'text', isRequired, className, ...props }) => {
    return (
        <Field name={name}>
            {({ field, meta }) => (
                <FormField className={className}>
                    {label && <FormField.Label required={isRequired}>{label}</FormField.Label>}

                    {input && input(field)}
                    {!input && (
                        <TextInputField
                            type={inputType}
                            placeholder={placeholder}
                            disabled={props.isDisabled}
                            className="+full-width"
                            {...field}
                        />
                    )}
                    {props.info && (
                        <FormField.Info><p>{props.info}</p></FormField.Info>
                    )}
                    {meta.touched && meta.error && (
                        <FormField.Error><p>{meta.error}</p></FormField.Error>
                    )}
                </FormField>
            )}
        </Field>
    );
};

FormTextField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    info: PropTypes.string,
    input: PropTypes.func,
    inputType: PropTypes.oneOf(['text', 'email', 'tel', 'password']),
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
};

export default FormTextField;
