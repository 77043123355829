import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import classNames from 'classnames';
import SessionPackPaymentScreen from './Screens/SessionPackPaymentScreen';
import styles from './app.module.scss';
import { usePro } from './Contexts/ProContext';
import NoMatchScreen from './Screens/NoMatchScreen';
import PackagePaymentScreen from './Screens/PackagePaymentScreen';
import MembershipPaymentScreen from './Screens/MembershipPaymentScreen';
import ClassPassPaymentScreen from './Screens/ClassPassPaymentScreen';
import ClassDatePaymentScreen from './Screens/ClassDatePaymentScreen';
import UpdateCardScreen from './Screens/UpdateCardScreen';
import {history} from "./index";

function App() {
    const { pro } = usePro()!;

    const getContainerStyle = (): {} => {
        return pro.brandColor ? { backgroundColor: pro.brandColor } : {};
    };

    return (
        <section className={styles.container} style={getContainerStyle()}>
            <section className={styles.content}>
                <Router history={history}>
                    <Switch>
                        <Route path="/card" component={UpdateCardScreen} />
                        <Route path="/class-pass/:uuid" component={ClassPassPaymentScreen} />
                        <Route path="/class-date/:uuid" component={ClassDatePaymentScreen} />
                        <Route path="/session-pack/:uuid" component={SessionPackPaymentScreen} />
                        <Route path="/package/:uuid" component={PackagePaymentScreen} />
                        <Route path="/membership/:uuid" component={MembershipPaymentScreen} />
                        <Route path="*" component={NoMatchScreen} />
                    </Switch>
                </Router>
            </section>
            <div className={classNames(styles.poweredBy, { [styles.darkBrand]: pro.darkBrand })}>
                <a href="https://striive.co/?utm_source=payment-portal&utm_medium=link&utm_campaign=powered_by_link" target="_blank" rel="noopener noreferrer">Powered by Striive</a>
            </div>
        </section>
    );
}

export default App;
