import React, { FormEvent, FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import ClassPassTransport from '../../Transport/ClassPassTransport';
import PaymentScreen, { PaymentAmountConfirmation, RenderConfirmationProps } from '../PaymentScreen';
import { useTransactable } from '../../Contexts/TransactableResourceContext';
import ClassPass from '../../Types/ClassPass';
import ContentSection from '../../Components/ContentSection';
import { PrimaryButton } from '../../Components/Button';
import * as Sentry from "@sentry/react";

interface RouteParams {
    uuid: string
}

const ClassPassPaymentScreen: FunctionComponent = () => {
    const { setAmount } = useTransactable()!;
    let { uuid: classPassUuid } = useParams<RouteParams>();
    const [ isBusy, setIsBusy ] = useState(false);

    async function handleLoadPaymentData() {
        const data = await ClassPassTransport.getParticipation(classPassUuid);
        setAmount(data.data.resource.outstanding);
        return data;
    }

    async function handlePurchaseConfirmed(event: FormEvent, onConfirmed: PaymentAmountConfirmation) {
        event.preventDefault();
        setIsBusy(true);

        try {
            const { data } = await ClassPassTransport.createPayment(classPassUuid);
            onConfirmed(data);
        }
        catch (e) {
            Sentry.captureException(e);
        }
    }

    function handleRenderConfirmation({ resource, handleConfirmed }: RenderConfirmationProps<ClassPass>) {
        return (
            <ContentSection title="Confirm amount">
                <form onSubmit={(event) => handlePurchaseConfirmed(event, handleConfirmed)}>
                    <div>
                        <h4 style={{ margin: '0 0 8px' }}>Description:</h4>
                        <p style={{ whiteSpace: 'pre-wrap' }}>{resource.description ?? 'No description'}</p>
                    </div>
                    <div>
                        <h4 style={{ margin: '0 0 8px' }}>Terms:</h4>
                        <p>{resource.terms ?? 'No terms'}</p>
                    </div>

                    <div style={{ marginTop: 32 }}>
                        <PrimaryButton isLoading={isBusy}>Continue</PrimaryButton>
                    </div>
                </form>
            </ContentSection>
        );
    }

    return (
        <PaymentScreen
            errorString="It appears as though this class pass no longer exists, or has been paid in full."
            amountEditable={false}
            loadPaymentData={handleLoadPaymentData}
            renderConfirmation={handleRenderConfirmation}
        />
    );
};

export default ClassPassPaymentScreen;
