import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './form-field.module.scss';

interface Props {
    required?: boolean,
    for?: string
}

const Label: FunctionComponent<Props> = ({ required = false, for: htmlFor, ...props }) => (
    <label className={styles.label} htmlFor={htmlFor} {...props}>
        {props.children}
        {required && (
            <span>*</span>
        )}
    </label>
);

Label.propTypes = {
    required: PropTypes.bool,
};

export default Label;
