import BaseTransport from './BaseTransport';

class ClassPassTransport extends BaseTransport {
    async getParticipation(uuid: string) {
        return await this.get(`/class-passes/${uuid}`);
    }

    async createPayment(uuid: string) {
        return await this.post(`/class-passes/${uuid}/payments`);
    }
}

export default new ClassPassTransport();
