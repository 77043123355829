import BaseTransport from './BaseTransport';

class MembershipTransport extends BaseTransport {
    async getMembership(uuid: string, params = {}) {
        return await this.get(`/memberships/${uuid}`, params);
    }

    async createSubscription(uuid: string, params: {}) {
        return await this.post(`/memberships/${uuid}/create`, params);
    }

    async subscribe(uuid: string, params: {}) {
        return await this.post(`/memberships/${uuid}/complete`, params);
    }
}

export default new MembershipTransport();
