import React, { FunctionComponent } from 'react';
import styles from './app-container.module.scss';
import classNames from 'classnames';
import { usePro } from '../../Contexts/ProContext';

interface Props {
    className?: string
}

const AppContainer: FunctionComponent<Props> = ({ className, children }) => {
    const { pro } = usePro()!;

    return (
        <div className={classNames(styles.container, className, { [styles.hasBackButton]: !!pro.portalUrl })}>
            {children}
        </div>
    );
};

export default AppContainer;
