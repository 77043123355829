import React, { FunctionComponent } from 'react';
import styles from './successful-payment.module.scss';
import ContentSection from '../ContentSection';
import Message from '../Message';
import ReturnToPortalButton from '../ReturnToPortalButton';

interface Props {
    content?: string
}

const SuccessfulPayment: FunctionComponent<Props> = ({ content = 'Your payment was successful!' }) => {
    return (
        <ContentSection title="Thank you">
            <div className={styles.section}>
                <Message type="positive" content={content} />

                <ReturnToPortalButton text="Return to client area" />
            </div>
        </ContentSection>
    );
};

export default SuccessfulPayment;
