import BaseTransport from './BaseTransport';

class PackageTransport extends BaseTransport {
    async getPackage(uuid: string, params = {}) {
        return await this.get(`/packages/${uuid}`, params);
    }

    async createPurchase(uuid: string, params: {}) {
        return await this.post(`/packages/${uuid}/payments`, params);
    }
}

export default new PackageTransport();
