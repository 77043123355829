import React, { FunctionComponent, useEffect, useState, Fragment } from 'react';
import FormField from '../FormField';
import CustomerTransport from '../../Transport/CustomerTransport';
import StoredCard from '../../Types/StoredCard';
import Loader from '../Loader';
import RadioField from '../RadioField';
import styles from './payment-method-list.module.scss';
import PaymentMethod from '../../Types/PaymentMethod';
import { CustomerData } from '../../Screens/PaymentScreen';
import * as Sentry from "@sentry/react";

interface Props {
    customer: CustomerData,
    paymentMethod: PaymentMethod,
    onMethodsLoaded(): void,
    onPaymentMethodChange(value: PaymentMethod): void,
}

const PaymentMethodList: FunctionComponent<Props> = ({ customer, paymentMethod, onMethodsLoaded, onPaymentMethodChange }) => {
    const [isBusy, setIsBusy] = useState(true);
    const [storedCards, setStoredCards] = useState<StoredCard[]>([]);

    async function fetchPaymentMethods() {
        try {
            const { data } = await CustomerTransport.getPaymentMethods({ identifier: customer.identifier });
            setStoredCards(data);
        }
        catch (e) {
            Sentry.captureException(e);
        }

        setIsBusy(false);
        onMethodsLoaded();
    }

    useEffect(() => {
            fetchPaymentMethods();
    }, [ customer.identifier ]);

    useEffect(() => {
        if (storedCards.length > 0) {
            onPaymentMethodChange({
                type: 'existing',
                paymentMethodId: storedCards[0].paymentMethodId
            });
        }
    }, [ storedCards ]);

    function handleStoredCardSelected(card: StoredCard) {
        onPaymentMethodChange({
            type: 'existing',
            paymentMethodId: card.paymentMethodId
        });
    }

    return (
        <div className={styles.container}>
            {isBusy && <Loader />}
            {!isBusy && (
                <Fragment>
                    <FormField>
                        <FormField.Label>Payment method</FormField.Label>
                        <FormField.Input>
                            {storedCards.map((card, index) => (
                                <div key={index} className={styles.method}>
                                    <RadioField
                                        label={`${card.brand} ending ${card.last4}`}
                                        checked={paymentMethod.paymentMethodId === card.paymentMethodId}
                                        onChange={() => handleStoredCardSelected(card)}
                                    />
                                </div>
                            ))}
                            <div className={styles.method}>
                                <RadioField
                                    label="New card"
                                    checked={paymentMethod.type === 'new'}
                                    onChange={() => onPaymentMethodChange({ type: 'new' })}
                                />
                            </div>
                        </FormField.Input>
                    </FormField>
                </Fragment>
            )}
        </div>
    );
};

export default PaymentMethodList;
