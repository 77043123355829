import { createContext, useContext } from 'react';
import { IntentData } from '../Components/SpecifyAmountForm';

export interface TransactableResourceContextInterface {
    amount?: number,
    paymentIntent?: IntentData,
    setAmount(amount: number): void,
    setPaymentIntent(paymentIntent?: IntentData): void,
}

export const TransactableResourceContext = createContext<TransactableResourceContextInterface | undefined>(undefined);
export const useTransactable = () => useContext(TransactableResourceContext);
