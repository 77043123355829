import React, { FunctionComponent } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

interface Props {
    accountId: string
}

const StripeWrapper: FunctionComponent<Props> = ({ accountId, children }) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!, {
        stripeAccount: accountId
    });

    return (
        <Elements stripe={stripePromise}>{children}</Elements>
    );
};

export default StripeWrapper;
