import axios from 'axios';
import config from '../Config';

axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default class BaseTransport {
    request(requestConfig: any) {
        return axios.request({
            baseURL: `${config.apiUrl}/payments/v1`,
            ...requestConfig
        });
    }

    async get(url: string, params = {}, config = {}) {
        return await this.request({
            method: 'get',
            url,
            params,
            ...config
        });
    }

    async post(url: string, data = {}, config = {}) {
        return await this.request({
            method: 'post',
            url,
            data,
            ...config
        });
    }

    async put(url: string, data = {}) {
        return await this.request({
            method: 'put',
            url,
            data,
        });
    }

    async delete(url: string) {
        return await this.request({
            method: 'delete',
            url,
        });
    }
}