import React, { FunctionComponent, useEffect, useState } from "react";
import AppHeader from "../../Components/AppHeader";
import AppContainer from "../../Components/AppContainer";
import { usePro } from "../../Contexts/ProContext";
import AppLoader from "../../Components/AppLoader";
import MakePaymentForm from "../../Components/MakePaymentForm";
import { IntentData } from "../../Components/SpecifyAmountForm";
import { useTransactable } from "../../Contexts/TransactableResourceContext";
import SuccessfulPayment from "../../Components/SuccessfulPayment";
import ErrorPanel from "../../Components/ErrorPanel";
import { StripeResult } from "../../Components/PayWithNewCardForm";
import * as Sentry from "@sentry/react";

export type PaymentAmountConfirmation = (paymentIntent: IntentData) => void;

export interface CustomerData {
  identifier: string;
  name: string;
}

export interface RenderConfirmationProps<T> {
  resource: T;
  customer?: CustomerData;
  paymentIntent: IntentData | undefined;
  handleConfirmed: PaymentAmountConfirmation;
}

interface Props<T> {
  errorString: string;
  amountEditable: boolean;
  loadPaymentData(): any;
  renderConfirmation(
    renderConfirmationProps: RenderConfirmationProps<T>
  ): JSX.Element;
  onBeforeConfirm?(): void;
}

const errorGenericString = "Sorry, there was a problem. Please try again.";

const PaymentScreen: FunctionComponent<Props<any>> = (props) => {
  const {
    errorString,
    loadPaymentData,
    renderConfirmation,
    amountEditable,
    onBeforeConfirm,
    // onSetupComplete
  } = props;

  const { setPro } = usePro()!;
  const { paymentIntent, setPaymentIntent } = useTransactable()!;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [step, setStep] = useState<"confirm" | "payment" | "success" | "error">(
    "confirm"
  );
  const [customer, setCustomer] = useState<CustomerData>();
  const [resource, setResource] = useState<any>();

  async function load() {
    setIsLoading(true);

    try {
      const { data } = await loadPaymentData();
      setPro(data.pro);
      setCustomer(data.customer);
      setResource(data.resource);
    } catch (e) {
      Sentry.captureException(e);

      const error =
        e.response?.status === 503
          ? "We are currently performing some system updates and will be back soon."
          : e.response?.data?.message ?? errorGenericString;

      setError(
        e.response?.status === 403
          ? "Sorry, payments are currently not being accepted."
          : error
      );
    }

    setIsLoading(false);
  }

  async function handlePaymentAttempted() {
    if (typeof onBeforeConfirm === "undefined") {
      return Promise.resolve();
    }

    await onBeforeConfirm();
  }

  function handleConfirmed(paymentIntent?: IntentData) {
    setPaymentIntent(paymentIntent);
    setStep("payment");
  }

  async function handlePaymentSuccess(result: StripeResult) {
    // if (typeof result.setupIntent !== 'undefined' && typeof onSetupComplete === 'function') {
    //     await onSetupComplete(result.setupIntent!);
    // }
    setStep("success");
  }

  useEffect(() => {
    if (step === "confirm") {
      load();
    }
  }, [step]);

  if (isLoading) {
    return <AppLoader />;
  }

  if (error) {
    return <ErrorPanel error={error} />;
  }

  return (
    <AppContainer>
      <AppHeader />

      {step === "confirm" &&
        resource &&
        renderConfirmation({
          resource,
          customer,
          paymentIntent,
          handleConfirmed,
        })}
      {step === "payment" && resource && (
        <MakePaymentForm
          amountEditable={amountEditable}
          resource={resource!}
          customer={customer!}
          paymentIntent={paymentIntent!}
          onSuccess={handlePaymentSuccess}
          onBeforeConfirm={handlePaymentAttempted}
          onRequestChangeAmount={() => setStep("confirm")}
        />
      )}
      {step === "success" && <SuccessfulPayment />}
    </AppContainer>
  );
};

export default PaymentScreen;
