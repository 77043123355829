import React, { FunctionComponent, useState } from 'react';
import { TransactableResourceContext } from '../../Contexts/TransactableResourceContext';
import { IntentData } from '../SpecifyAmountForm';

export const TransactableResourceProvider: FunctionComponent = ({ children }) => {
    const [ amount, setAmount ] = useState<number>();
    const [ paymentIntent, setPaymentIntent ] = useState<IntentData>();

    return (
        <TransactableResourceContext.Provider value={{ amount, paymentIntent, setAmount, setPaymentIntent }}>
            {children}
        </TransactableResourceContext.Provider>
    );
};
