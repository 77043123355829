import CurrencyData from '../Types/CurrencyData';

export const getCurrencyDivisor = (currencyData: CurrencyData): number => {
    const power = Math.max(currencyData.decimals, 0);
    return (Math.pow(10, power));
}

/**
 * Display the given value as a currency in the given style
 * @param value
 * @param currencyData
 * @param simplify
 * @param withSymbol
 */
export const currencyDisplay = (value: number, currencyData: CurrencyData, simplify = true, withSymbol = true): string => {
    const divisor = getCurrencyDivisor(currencyData);

    let decimals = currencyData.decimals;
    const symbol = withSymbol ? currencyData.symbol : '';

    if (simplify && value % divisor! === 0) {
        decimals = 0;
    }

    value = value / divisor;
    const formatted = value.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    return `${symbol}${formatted}`;
};