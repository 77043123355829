import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './message.module.scss';
import { ReactComponent as CheckIcon } from '../../Icons/check.svg';
import { ReactComponent as WarningIcon } from '../../Icons/warning-triangle.svg';

interface Props {
    type: 'positive' | 'negative' | 'warning',
    content: string | JSX.Element,
    className?: string
}

const Message: FunctionComponent<Props> = ({ type, content, className }) => {
    return (
        <div className={classNames(styles.container, styles[type], className)}>
            <div className={styles.icon}>
                {type === 'positive' && <CheckIcon />}
                {type === 'negative' && <WarningIcon />}
                {type === 'warning' && <WarningIcon />}
            </div>

            {content}
        </div>
    );
};

export default Message;
